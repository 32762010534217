body{
  background: #FCFCFC;
}
*{
  outline:none!important;
}
form {
  margin: 100px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
form input, form select, .MuiSelect-select {
  padding: 13.5px 14px!important;
  background: #FFF!important;
}
form fieldset {
  height: 50px;
  top: 0!important;
  border: 1px solid #C1C3C2!important;
  
  border-radius: 0!important;
}

form .Mui-error fieldset{
  border-color: #f00!important;
}
.error-message,
.MuiFormHelperText-root.Mui-error, .MuiFormHelperText-root.error-message {
  color: #f00;
  font-size: 10px;
  position: absolute;
  left: 0;
  top: 100%;
  margin-left: 0!important;
}
.Mui-disabled {
  color: #000 !important;
}
.Mui-disabled input {
  color: #000 !important;
  -webkit-text-fill-color: #000 !important;
}

.MuiGrid-root {
  position: relative;
}

select {
  max-width: 100%;
  height: 100%;
  padding: 16.5px 14px;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
}


.title{
  position: relative;
  margin: 50px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title:first-child{
  margin-top: 0;
}
.title::before{
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background: #000;
  position: absolute;
  z-index: 0;
  left: 0;
  top:52%;
}

.title h2{
  position: relative;
  z-index: 1;
  color: #0F121F;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #FFF;
  padding: 0 20px;
  margin: 0;
}

.MuiFormControl-root legend{
  display: none;
}
.App .MuiFormLabel-root {
  position: relative;
  background: transparent;
  margin: 0;
  left: 0;
  top: 0;
  transform: unset;
  margin-bottom: 10px;
  font-size: 14px;
}

form .MuiButton-contained{
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #0F121F!important;
  border-radius: 0!important;
  box-shadow: none!important;
  width: 220px;
  padding: 0 20px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:10px auto 0 auto;
}


@media (max-width:767px){
  form{
    margin: 70px 0;
  }
  .title h2{
    font-size: 24px;
  }
  .title{
    margin: 30px 0 0 0;
  }
  form .MuiButton-contained{
    width: 100%;
  }
}